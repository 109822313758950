import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import Hero from '../components/hero';
import Portfolio from '../components/portfolio';
import Services from '../components/services';

function IndexPage() {
  return (
    <Layout>
    {/* Search engine optimization */}
      <SEO
        keywords={[
          `bytewrap`,
          `web design`,
          `web development`,
          `mobile apps`,
          `blockchain`,
          `eos dapps`,
        ]}
        title="Your web & mobile apps development company"
      />

      {/* Hero Section */}
      <Hero />
      {/* Portfolio Section */}
      <Portfolio />
      {/* Services Section */}
      <Services />
    </Layout>
  );
}

export default IndexPage;
