import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import PortfolioItem from './portfolio-item';

const DEFAULT = 'border-4 border-solid border-black ';

const ITEMMARGIN = {
  item2: '-mt-32',
  item3: 'mt-16 lg:mt-32',
  item4: 'md:-mt-8 xl:mt-0',
}; //margin for tablet and above screens

const COLORS = {
  esusu: 'bg-esusu shadow-esusu-m lg:shadow-esusu',
  challenge: 'bg-challenge shadow-challenge-m lg:shadow-challenge',
  vms: 'bg-vms shadow-vms-m lg:shadow-vms',
  ims: 'bg-ims shadow-ims-m lg:shadow-ims',
};

const PROJECTNAME = {
  p1: 'esusu - ',
  p2: 'challenge - ',
  p3: 'vms - ',
  p4: 'ims - ',
};

const PROJECTINFO = {
  p1: 'fintech mobile app empowering credit reporting.',
  p2: 'location based eos tokens rewarding app. ',
  p3: 'visitor management app & concierge portal.',
  p4: 'incident management apps & dashboard.',
};

const PROJECTURL = {
  p1:
    'https://apps.apple.com/us/app/esusu-savings/id1440199142#?platform=iphone',
  p2: 'https://challengedapp.io',
  p3: 'https://apps.apple.com/us/app/vms-manage-visitors/id1493484227',
  p4: 'https://apps.apple.com/us/app/id1525585132',
};

const IMGALT = {
  p1: 'esusu - fintech mobile ios, android app',
  p2: 'challenge - eos blockchain mobile ios, android app',
  p3: 'vms - ipad and web app',
  p4: 'ims - ios, android and web app',
};

const Portfolio = () => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "esusu-app.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1020) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      img2: file(relativePath: { eq: "challenge-blockchain-app.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1020) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      img3: file(relativePath: { eq: "vms-ipad.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1020) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      img4: file(relativePath: { eq: "ims-app-web.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1020) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <section className="mt-16 xxl:mt-32">
      <h1 id="work-sec" className="inline-block font-bold">
        projects<br></br>delivered
      </h1>
      {/* view for tablets and above */}
      <div className="hidden md:grid grid-cols-2 xxl:mt-16 gap-8 lg:gap-24 xxl:gap-32 max-w-screen-xl mx-auto ">
        <div className="flex flex-col">
          <PortfolioItem
            img={data.img1.childImageSharp.fluid}
            imgAlt={`${IMGALT['p1']}`}
            projectName={`${PROJECTNAME['p1']}`}
            projectInfo={`${PROJECTINFO['p1']}`}
            projectUrl={`${PROJECTURL['p1']}`}
            projectCat={'mobile . web'}
            themeInfo={`${DEFAULT} ${COLORS['esusu']}`}
            bgColor={'bg-esusu'}
          />
          <PortfolioItem
            img={data.img3.childImageSharp.fluid}
            imgAlt={`${IMGALT['p3']}`}
            projectName={`${PROJECTNAME['p3']}`}
            projectInfo={`${PROJECTINFO['p3']}`}
            projectUrl={`${PROJECTURL['p3']}`}
            themeInfo={`${DEFAULT} ${ITEMMARGIN['item4']} ${COLORS['vms']}`}
            projectCat={'ipad . web'}
            bgColor={'bg-vms'}
          />
        </div>
        <div className="flex flex-col">
          <PortfolioItem
            img={data.img2.childImageSharp.fluid}
            imgAlt={`${IMGALT['p2']}`}
            projectName={`${PROJECTNAME['p2']}`}
            projectInfo={`${PROJECTINFO['p2']}`}
            themeInfo={`${DEFAULT} ${ITEMMARGIN['item3']} ${COLORS['challenge']}`}
            projectCat={'mobile . blockchain'}
            bgColor={'bg-challenge'}
          />
          <PortfolioItem
            img={data.img4.childImageSharp.fluid}
            imgAlt={`${IMGALT['p4']}`}
            projectName={`${PROJECTNAME['p4']}`}
            projectInfo={`${PROJECTINFO['p4']}`}
            projectUrl={`${PROJECTURL['p4']}`}
            themeInfo={`${DEFAULT} ${ITEMMARGIN['item4']} ${COLORS['ims']}`}
            projectCat={'mobile . web'}
            bgColor={'bg-ims'}
          />
        </div>
      </div>
      {/* view for mobile devices */}
      <div className=" md:hidden grid grid-flow-row gap-12 mt-12">
        <PortfolioItem
          img={data.img1.childImageSharp.fluid}
          imgAlt={`${IMGALT['p1']}`}
          projectName={`${PROJECTNAME['p1']}`}
          projectInfo={`${PROJECTINFO['p1']}`}
          projectCat={'mobile . web'}
          themeInfo={`${DEFAULT} ${COLORS['esusu']}`}
          bgColor={'bg-esusu'}
        />
        <PortfolioItem
          img={data.img2.childImageSharp.fluid}
          imgAlt={`${IMGALT['p2']}`}
          projectName={`${PROJECTNAME['p2']}`}
          projectInfo={`${PROJECTINFO['p2']}`}
          themeInfo={`${DEFAULT} ${COLORS['challenge']}`}
          projectCat={'mobile . blockchain'}
          bgColor={'bg-challenge'}
        />
        <PortfolioItem
          img={data.img3.childImageSharp.fluid}
          imgAlt={`${IMGALT['p3']}`}
          projectName={`${PROJECTNAME['p3']}`}
          projectInfo={`${PROJECTINFO['p3']}`}
          themeInfo={`${DEFAULT} ${COLORS['vms']}`}
          projectCat={'ipad . web'}
          bgColor={'bg-vms'}
        />

        <PortfolioItem
          img={data.img4.childImageSharp.fluid}
          imgAlt={`${IMGALT['p4']}`}
          projectName={`${PROJECTNAME['p4']}`}
          projectInfo={`${PROJECTINFO['p4']}`}
          themeInfo={`${DEFAULT} ${COLORS['ims']}`}
          projectCat={'mobile . web'}
          bgColor={'bg-ims'}
        />
      </div>
    </section>
  );
};

export default Portfolio;
