import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      heroBg: file(relativePath: { eq: "hero-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1020) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <section className='flex flex-col md:flex-row-reverse md:items-center md:justify-between'>
      <Img
        alt='girl putting puzzle pieces together'
        className='block md:w-1/2 xxl:w-2/5'
        fluid={data.heroBg.childImageSharp.fluid}
      />
      <div className='flex flex-col text-left'>
        <h1 className='inline-block mb-6'>
          your<br></br>web & mobile<br></br>development agency.
        </h1>

        <p>
          Let us do the heavy lifting for{` `}
          <OutboundLink
            className='font-bold text-funky-blue'
            href='mailto:hi@bytewrap.com'>
            your next project.
          </OutboundLink>
        </p>
      </div>
    </section>
  );
};

export default Hero;
