import React, { Component } from 'react';

class services extends Component {
  render() {
    return (
      <section className="mt-16 xl:mt-32">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col">
            <h1 className="mb-4 xl:mb-8">strategy.</h1>
            <p>brainstorming</p>
            <p>roadmapping</p>
            <p>sprint planning</p>
          </div>
          <div className="flex flex-col mt-8 md:mt-0">
            <h1 className="mb-4 xl:mb-8">design.</h1>
            <p>research</p>
            <p>design sprints</p>
            <p>experience design</p>
            <p>human interface design</p>
          </div>
          <div className="flex flex-col mt-8 md:mt-0">
            <h1 className="mb-4 xl:mb-8">development.</h1>
            <p>web &bull; reactjs</p>
            <p>iOS &bull; android &bull; react native</p>
            <p>nodejs &bull; mongodb &bull; aws</p>
            <p>blockchain &bull; eos &bull; ethereum</p>
          </div>
        </div>
      </section>
    );
  }
}

export default services;
