import React, { Component } from "react";
import { string, any } from "prop-types";
import Img from "gatsby-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";
class PortfolioItem extends Component {
  static propTypes = {
    img: any,
    imgAlt: string,
    projectName: string,
    projectInfo: string,
    projectCat: string,
    projectUrl: string,
    themeInfo: string,
    bgColor: string,
  };

  render() {
    return (
      <OutboundLink
        href={this.props.projectUrl}
        target='_blank'
        rel='noreferrer'>
        <div className='grid grid-flow-row gap-6 md:gap-8 md:mt-16'>
          <div className={this.props.themeInfo}>
            <Img
              fluid={this.props.img}
              alt={this.props.imgAlt}
              backgroundColor={this.props.bgColor}
            />

            {/* for sidetext <div className="table absolute inset-x-0 top-0 left-0 pl-2 pr-2 w-20 h-1 transform translate-y-full bg-white"></div> */}
            {/* <p className="hidden absolute inset-x-0 top-0 left-0 pl-2 -mt-1 text-xl lg:text-2xl transform -translate-x-1 -translate-y-1 rotate-90 origin-top-left bg-white">
            {this.props.projectCat}
          </p> */}
          </div>
          <p className='break-words'>
            <b>{this.props.projectName}</b>
            {this.props.projectInfo}
          </p>
        </div>
      </OutboundLink>
    );
  }
}

export default PortfolioItem;
